<template>
  <div class="dashboard-card">
    <h4 class="card-title">{{ registration.event.name }}</h4>
    <p class="city" v-if="registration.event.city">
      {{ registration.event.city.name }},
      {{ registration.event.city.country }}
    </p>
    <div class="dates">
      <p>
        <b>{{ eventDateFormatter(registration.event.startDate) }}</b>
      </p>
    </div>
    <ul
      class="labels"
      v-if="registration.event.tour.tourType || registration.event.pdgaType"
    >
      <li
        class="card-label sunset tour-type"
        v-if="registration.event.tour.tourType"
      >
        {{ registration.event.tour.tourType.name }}
      </li>
      <li
        class="card-label ocean"
        v-if="
          registration.event.pdgaType &&
          registration.event.pdgaType.type != 'Unsanctioned'
        "
      >
        {{ registration.event.pdgaType.name }}
      </li>
    </ul>

    <div class="buttons">
      <button class="view-event">
        <router-link
          class="button-link"
          :to="{
            name: 'public-event-results',
            params: { eventId: registration.event.id },
          }"
        >
          <p class="large"><b>View event</b></p></router-link
        >
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "TheDashboardFinishedEventsCard",
  props: ["registration"],
  data() {
    return {};
  },
  computed: {},
  methods: {
    eventDateFormatter(date) {
      return moment(date).format("ddd D MMM [at] HH:mm");
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";

.card-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.city {
  margin-top: 8px;
}
.dates {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  > p {
    margin-right: 12px;
  }
}
.labels {
  margin-top: 15px;
}

.buttons {
  margin-top: 20px;
  display: flex;
  width: 100%;

  .button-link {
    color: $midnight;
    text-decoration: none;
  }

  button {
    width: 100%;
    border: none;
    background: $cloud;
    border-radius: 6px;
    height: 48px;

    &:nth-child(2) {
      margin-left: 12px;
    }

    &.check-in-now {
      background: $sunrise;
      &:hover {
        background: #f0aa00;
      }
    }

    &:hover {
      background: $fog;
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
}
</style>
