<template>
  <div class="dashboard-card casual">
    <div class="left">
      <h4 class="card-title">{{ courseName }}</h4>
      <p class="city" v-if="this.scorecard.layoutVersion.layout.course">
        {{ this.scorecard.layoutVersion.layout.name }}
      </p>
      <div class="dates">
        <p>
          <b>{{ eventDateFormatter(scorecard) }}</b>
        </p>
      </div>
      <ul class="labels">
        <li class="card-label ocean">
          {{ this.scorecard.layoutVersion.holes.length }} Holes
        </li>
        <li class="card-label strom par-count">Par {{ layoutPar }}</li>
      </ul>
    </div>
    <div class="right">
      <div class="player-result">
        <h1 :class="parClass(usersResult)">{{ parFormatter(usersResult) }}</h1>
      </div>
    </div>
    <div class="dashboard-card-footer" v-if="scorecard.players.length > 1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        class="with-icon"
      >
        <path
          id="Path_1773"
          data-name="Path 1773"
          d="M8.9,6.751h0a4,4,0,1,0-5.8,0h0A6,6,0,0,0,0,12H1A5,5,0,0,1,3.963,7.438a3.973,3.973,0,0,0,4.074,0A5,5,0,0,1,11,12h1A6,6,0,0,0,8.9,6.751ZM3,4A3,3,0,1,1,6,7,3,3,0,0,1,3,4Z"
          fill="#2d323c"
        />
      </svg>

      <p class="small headline">With:</p>
      <p
        class="other-player small"
        v-for="(player, playerIndex) in otherPlayers"
      >
        <template
          v-if="
            playerIndex == otherPlayers.length - 1 && otherPlayers.length > 2
          "
          >and</template
        >
        {{ playerName(player) }}
        <b
          class="other-player-result"
          :class="parClass(playerResult(player))"
          >{{ parFormatter(playerResult(player)) }}</b
        >
        <template
          v-if="
            otherPlayers.length > 1 && playerIndex < otherPlayers.length - 2
          "
          >,</template
        >
      </p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "TheDashboardCasualScorecardCard",
  props: ["scorecard"],
  data() {
    return {};
  },
  computed: {
    otherPlayers() {
      return this.scorecard.players.filter((player) => {
        return player.user.id != this.userInfo.sessionInfo.userId;
      });
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    usersResult() {
      const player = this.scorecard.players.find(
        (x) => x.user.id == this.userInfo.sessionInfo.userId
      );

      let playersPar = 0;

      player.results.forEach((result) => {
        playersPar += result.score - result.hole.par;
      });

      return playersPar;
    },
    layoutPar() {
      let parCount = 0;
      this.scorecard.layoutVersion.holes.forEach((hole) => {
        parCount += hole.par;
      });
      return parCount;
    },
    courseName() {
      if (this.scorecard.layoutVersion.layout.course) {
        return this.scorecard.layoutVersion.layout.course.name;
      } else {
        return this.scorecard.layoutVersion.layout.name;
      }
    },
  },
  methods: {
    playerResult(player) {
      let playersPar = 0;

      player.results.forEach((result) => {
        playersPar += result.score - result.hole.par;
      });

      return playersPar;
    },
    playerName(player) {
      return (
        player.user.name || `${player.user.firstName} ${player.user.lastName}`
      );
    },
    parClass(par) {
      if (par > 0) return "above";
      if (par < 0) return "below";
      else return "even";
    },
    parFormatter(par) {
      if (par == 0) return "E";
      if (par > 0) return "+" + par;
      else return par;
    },
    eventDateFormatter(pool) {
      return moment(pool.date).format("ddd D MMM [at] HH:mm");
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";

.dashboard-card {
  &.casual {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
}

.card-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.city {
  margin-top: 8px;
}
.dates {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  > p {
    margin-right: 12px;
  }
}
.labels {
  width: 100%;
  display: flex;
  margin-top: 15px;

  .par-count {
    margin-left: 8px;
  }
}

.buttons {
  margin-top: 20px;
  display: flex;
  width: 100%;

  button {
    width: 100%;
    border: none;
    background: $cloud;
    border-radius: 6px;
    height: 48px;

    &:nth-child(2) {
      margin-left: 12px;
    }

    &.check-in-now {
      background: $sunrise;
      &:hover {
        background: #f0aa00;
      }
    }

    &:hover {
      background: $fog;
    }
  }
}

.left {
  width: 80%;
}
.above {
  color: $dusk;
}
.even {
  color: $strom;
}
.below {
  color: $grass;
}
.right {
  width: 20%;
  text-align: right;
  position: relative;

  h1 {
    position: absolute;
    top: -10px;
    right: 0;
  }
}
.dashboard-card-footer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid $sleet;
}

.other-player,
.headline {
  margin-right: 4px;
  white-space: nowrap;
  line-height: 1rem;
}
.with-icon {
  margin-right: 8px;
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
}
</style>
