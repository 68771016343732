var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.eventsLoaded && _vm.casualLoaded)?_c('div',{staticClass:"dashboard-activity-container"},[_c('p',{staticClass:"large upcoming-events-title"},[_vm._v("Your upcoming events")]),(_vm.upcomingEvents.length > 0)?_c('div',{staticClass:"upcoming-events"},_vm._l((_vm.upcomingEvents),function(registration){return _c('div',{staticClass:"dashboard-card"},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(registration.event.name))]),(registration.event.city)?_c('p',{staticClass:"city"},[_vm._v(" "+_vm._s(registration.event.city.name)+", "+_vm._s(registration.event.city.country)+" ")]):_vm._e(),_c('div',{staticClass:"dates"},[(_vm.singleRoundPoolEvent(registration.event))?[_c('p',[_c('b',[_vm._v(_vm._s(_vm.eventDateFormatter(registration.event.startDate)))])])]:[_vm._l((registration.event.rounds),function(round,roundIndex){return [_vm._l((round.pools),function(pool,poolIndex){return [_c('p',{staticClass:"small"},[_vm._v(" R"+_vm._s(roundIndex + 1)),(round.pools.length > 1)?[_vm._v("P"+_vm._s(_vm.$store.state.poolConversion[poolIndex]))]:_vm._e(),_c('b',[_vm._v(" "+_vm._s(_vm.eventDateFormatter(pool.date)))])],2)]})]})]],2),_c('ul',{staticClass:"labels"},[(registration.event.tour.tourType)?_c('li',{staticClass:"card-label sunset tour-type"},[_vm._v(" "+_vm._s(registration.event.tour.tourType.name)+" ")]):_vm._e(),(
            registration.event.pdgaType &&
            registration.event.pdgaType.type != 'Unsanctioned'
          )?_c('li',{staticClass:"card-label ocean"},[_vm._v(" "+_vm._s(registration.event.pdgaType.name)+" ")]):_vm._e()]),_c('div',{staticClass:"card-messages"},[(registration.status == 'PENDING')?[_c('WaitingList'),_c('p',[_vm._v("You’re on the waiting list")])]:(registration.status == 'ACCEPTED')?[(registration.player.onSite)?[_c('CheckIn',{attrs:{"color":"grass"}}),_vm._m(0,true)]:(!registration.player.onSite)?[_c('ConfirmedPlayers'),_c('p',[_vm._v("You're confirmed")])]:_vm._e(),(
              registration.event.checkInStartsAt != null &&
              !registration.player.onSite
            )?[(_vm.isBeforeCheckinOpens(registration.event))?_c('p',{staticClass:"check-in-message"},[_vm._v(" Check in opens"),_c('b',[_vm._v(" "+_vm._s(_vm.eventDateFormatter(registration.event.checkInStartsAt))+" ")])]):(_vm.isBeforeCheckinCloses(registration.event))?_c('p',{staticClass:"check-in-message"},[_vm._v(" Check in closes"),_c('b',[_vm._v(" "+_vm._s(_vm.eventDateFormatter(registration.event.checkInEndsAt)))])]):_vm._e()]:_vm._e()]:_vm._e()],2),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"view-event"},[_c('router-link',{staticClass:"button-link",attrs:{"to":{
              name: 'public-event-results',
              params: { eventId: registration.event.id },
            }}},[_c('p',{staticClass:"large"},[_c('b',[_vm._v("View event")])])])],1),(_vm.canCheckIn(registration))?_c('button',{staticClass:"check-in-now",on:{"click":function($event){return _vm.checkInPlayer(registration)}}},[_vm._m(1,true)]):_vm._e()])])}),0):_c('p',[_vm._v(" You have no upcoming events. "),_c('router-link',{attrs:{"to":"/events"}},[_vm._v("Find events to play")])],1),_c('p',{staticClass:"large finished-casual-title"},[_vm._v("Your finished events")]),(_vm.finishedEvents.length == 0)?_c('p',[_vm._v("You haven't played an event yet.")]):_vm._e(),_c('div',{staticClass:"finished-casual-container"},[_vm._l((_vm.finishedEvents),function(item){return _c('TheDashboardFinishedEventsCard',{key:item.id,attrs:{"registration":item}})}),(_vm.canFetchMoreRegistrations || _vm.feedLength < _vm.registrations.length)?_c('div',{staticClass:"load-more"},[_c('p',{on:{"click":function($event){return _vm.loadMoreRegistrations()}}},[_vm._v("Show more events")])]):_vm._e()],2),_c('p',{staticClass:"large finished-casual-title"},[_vm._v("Your casual rounds")]),(_vm.scorecards.length == 0)?_c('div',{staticClass:"buttonSectionDeviceSpecific"},[_vm._m(2)]):_vm._e(),_c('div',{staticClass:"finished-casual-container"},[_vm._l((_vm.scorecards),function(item){return _c('TheDashboardCasualScorecardCard',{key:item.id,attrs:{"scorecard":item}})}),(_vm.canFetchMoreScorecards)?_c('div',{staticClass:"load-more"},[_c('p',{on:{"click":function($event){return _vm.loadScorecards()}}},[_vm._v("Show more rounds")])]):_vm._e()],2)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('b',[_vm._v("You're checked in")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"large"},[_c('b',[_vm._v("Check in now")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" It doesn't look like you've played a casual round with Tjing yet. Get the app for "),_c('a',{attrs:{"href":"https://apps.apple.com/se/app/tjing/id1277060479"}},[_vm._v("iOS")]),_vm._v(" or "),_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.kidpilot.tjing"}},[_vm._v("Android")]),_vm._v(" to start scoring casual rounds. ")])}]

export { render, staticRenderFns }