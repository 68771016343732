<template>
  <div class="dashboard-activity-container" v-if="eventsLoaded && casualLoaded">
    <p class="large upcoming-events-title">Your upcoming events</p>
    <div class="upcoming-events" v-if="upcomingEvents.length > 0">
      <div class="dashboard-card" v-for="registration in upcomingEvents">
        <h4 class="card-title">{{ registration.event.name }}</h4>
        <p class="city" v-if="registration.event.city">
          {{ registration.event.city.name }},
          {{ registration.event.city.country }}
        </p>
        <div class="dates">
          <template v-if="singleRoundPoolEvent(registration.event)">
            <p>
              <b>{{ eventDateFormatter(registration.event.startDate) }}</b>
            </p>
          </template>
          <template v-else>
            <template v-for="(round, roundIndex) in registration.event.rounds">
              <template v-for="(pool, poolIndex) in round.pools">
                <p class="small">
                  R{{ roundIndex + 1
                  }}<template v-if="round.pools.length > 1"
                    >P{{ $store.state.poolConversion[poolIndex] }}</template
                  >
                  <b> {{ eventDateFormatter(pool.date) }}</b>
                </p>
              </template>
            </template>
          </template>
        </div>
        <ul class="labels">
          <li
            class="card-label sunset tour-type"
            v-if="registration.event.tour.tourType"
          >
            {{ registration.event.tour.tourType.name }}
          </li>
          <li
            class="card-label ocean"
            v-if="
              registration.event.pdgaType &&
              registration.event.pdgaType.type != 'Unsanctioned'
            "
          >
            {{ registration.event.pdgaType.name }}
          </li>
        </ul>
        <div class="card-messages">
          <template v-if="registration.status == 'PENDING'">
            <WaitingList />
            <p>You’re on the waiting list</p>
          </template>
          <template v-else-if="registration.status == 'ACCEPTED'">
            <template v-if="registration.player.onSite">
              <CheckIn color="grass" />
              <p>
                <b>You're checked in</b>
              </p>
            </template>

            <template v-else-if="!registration.player.onSite">
              <ConfirmedPlayers />
              <p>You're confirmed</p>
            </template>

            <template
              v-if="
                registration.event.checkInStartsAt != null &&
                !registration.player.onSite
              "
            >
              <p
                class="check-in-message"
                v-if="isBeforeCheckinOpens(registration.event)"
              >
                Check in opens<b>
                  {{ eventDateFormatter(registration.event.checkInStartsAt) }}
                </b>
              </p>
              <p
                v-else-if="isBeforeCheckinCloses(registration.event)"
                class="check-in-message"
              >
                Check in closes<b>
                  {{ eventDateFormatter(registration.event.checkInEndsAt) }}</b
                >
              </p>
            </template>
          </template>
        </div>

        <div class="buttons">
          <button class="view-event">
            <router-link
              class="button-link"
              :to="{
                name: 'public-event-results',
                params: { eventId: registration.event.id },
              }"
            >
              <p class="large"><b>View event</b></p></router-link
            >
          </button>
          <button
            class="check-in-now"
            v-if="canCheckIn(registration)"
            @click="checkInPlayer(registration)"
          >
            <p class="large"><b>Check in now</b></p>
          </button>
        </div>
      </div>
    </div>
    <p v-else>
      You have no upcoming events.
      <router-link to="/events">Find events to play</router-link>
    </p>
    <p class="large finished-casual-title">Your finished events</p>
    <p v-if="finishedEvents.length == 0">You haven't played an event yet.</p>
    <div class="finished-casual-container">
      <TheDashboardFinishedEventsCard
        v-for="item in finishedEvents"
        :registration="item"
        :key="item.id"
      />
      <div
        class="load-more"
        v-if="canFetchMoreRegistrations || feedLength < registrations.length"
      >
        <p @click="loadMoreRegistrations()">Show more events</p>
      </div>
    </div>
    <p class="large finished-casual-title">Your casual rounds</p>

    <div v-if="scorecards.length == 0" class="buttonSectionDeviceSpecific">
      <p>
        It doesn't look like you've played a casual round with Tjing yet. Get
        the app for
        <a href="https://apps.apple.com/se/app/tjing/id1277060479">iOS</a> or
        <a
          href="https://play.google.com/store/apps/details?id=com.kidpilot.tjing"
          >Android</a
        >
        to start scoring casual rounds.
      </p>
    </div>
    <div class="finished-casual-container">
      <TheDashboardCasualScorecardCard
        :scorecard="item"
        :key="item.id"
        v-for="item in scorecards"
      />
      <div class="load-more" v-if="canFetchMoreScorecards">
        <p @click="loadScorecards()">Show more rounds</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import WaitingList from "@/assets/icons/WaitingList";
import ConfirmedPlayers from "@/assets/icons/ConfirmedPlayers";
import CheckIn from "@/assets/icons/CheckIn";
import TheDashboardFinishedEventsCard from "@/components/TheDashboardFinishedEventsCard";
import TheDashboardCasualScorecardCard from "@/components/TheDashboardCasualScorecardCard";
import { orderBy } from "lodash";

export default {
  name: "DashboardActivity",
  components: {
    WaitingList,
    ConfirmedPlayers,
    TheDashboardFinishedEventsCard,
    TheDashboardCasualScorecardCard,
    CheckIn,
  },
  data() {
    return {
      casualLoaded: false,
      eventsLoaded: false,
      registrations: [],
      scorecards: [],
      fetchInterval: 6,
      feedLength: 6,
      registrationsStart: 72,
      canFetchMoreScorecards: true,
      canFetchMoreRegistrations: true,
      dataFromUserPlatform: "",
    };
  },
  computed: {
    userFavouriteEvents() {
      return this.$store.getters.favouriteEvents;
    },
    upcomingEvents() {
      const upcomingEvents = this.registrations.filter((registration) => {
        return (
          registration.event.status !== "COMPLETED" &&
          moment(registration.event.endDate)
            .endOf("day")
            .isAfter(moment().subtract(1, "days").endOf("day"))
        );
      });

      return orderBy(upcomingEvents, (reg) => reg.event.startDate, "asc");
    },
    finishedEvents() {
      const finishedEvents = this.registrations.filter((registration) => {
        return (
          registration.status == "ACCEPTED" &&
          (registration.event.status == "COMPLETED" ||
            moment(registration.event.endDate)
              .endOf("day")
              .isBefore(moment().subtract(1, "days").endOf("day")))
        );
      });

      return orderBy(
        finishedEvents,
        (reg) => reg.event.startDate,
        "desc"
      ).slice(0, this.feedLength);
    },
  },
  methods: {
    async unMarkEventasFavourite(favouriteId) {
      try {
        await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation{
              DeleteFavouriteEvent(favouriteId:"${favouriteId}")
            }
          `,
          },
        });

        this.$store.dispatch("loadFavouriteEvents");
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Removed favourite",
          type: "success",
        });
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "Error",
        });
      }
    },
    async checkInPlayer(registration) {
      const player = registration.player;

      console.log(player);

      const checkIn = await this.$axios({
        headers: {
          Authorization: this.$store.state.user.sessionInfo.token,
        },
        data: {
          query: `
            mutation UpdatePlayer($UpdatePlayer: UpdatePlayerInput!){
              UpdatePlayer(playerId:"${player.id}",input:$UpdatePlayer,asRole:USER){
                id
              }
            }
          `,
          variables: {
            UpdatePlayer: {
              onSite: true,
            },
          },
        },
      });

      if (checkIn.data.errors) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Something went wrong.",
          type: "error",
        });
        this.loadData();
      } else {
        player.onSite = true;
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Checked in",
          type: "success",
        });
      }
    },
    isMobilePlatform() {
      return /iPhone|iPad|iPod|Android/i.test(this.dataFromUserPlatform);
    },
    shouldShowAndroidButton() {
      if (this.isMobilePlatform) {
        return /iPhone|iPad|iPod/i.test(this.dataFromUserPlatform)
          ? false
          : true;
      }
    },
    shouldShowIOSButton() {
      if (this.isMobilePlatform) {
        return /Android/i.test(this.dataFromUserPlatform) ? false : true;
      }
    },
    loadMoreRegistrations() {
      if (
        this.finishedEvents.length < this.registrationsStart &&
        this.registrations.length > this.feedLength
      ) {
        this.feedLength += 6;
      } else {
        this.loadRegistrations();
      }
    },

    canCheckIn(registration) {
      return (
        registration.status == "ACCEPTED" &&
        registration.player.onSite == false &&
        moment().isBetween(
          registration.event.checkInStartsAt,
          registration.event.checkInEndsAt
        )
      );
    },
    isBeforeCheckinCloses(event) {
      return moment().isBefore(moment(event.checkInEndsAt));
    },
    isBeforeCheckinOpens(event) {
      return moment().isBefore(moment(event.checkInStartsAt));
    },
    singleDatePoolDateFormatter(event) {
      return moment(event.startDate).format("ddd D MMM [at] HH:mm");
    },

    eventDateFormatter(date) {
      return moment(date).format("ddd D MMM [at] HH:mm");
    },
    singleRoundPoolEvent(event) {
      const singleRound = event.rounds.length == 1 ? true : false;
      const singlePool = event.rounds[0].pools.length == 1 ? true : false;
      return singleRound && singlePool;
    },
    async loadScorecards(interval) {
      this.canFetchMoreScorecards = false;
      let fetchInterval = interval ? interval : this.fetchInterval;

      const userScorecards = await this.$axios({
        headers: {
          Authorization: this.$store.state.user.sessionInfo.token,
        },
        data: {
          query: `
          {
            me{
              scorecards(first:${fetchInterval + 1},skip:${
            this.scorecards.length
          }){
                id
                date
                status
                layoutVersion{
                  holes{
                    id
                    number
                    par
                  }
                  layout{
                    type
                    name
                    course{
                      id
                      name
                    }
                  }
                }
                players{
                  id
                  position
                  user{
                    ...on User{
                      id
                      firstName
                      lastName
                    }
                    ...on AnonymousUser{
                      id
                      name
                    }
                  }
                  results{
                    id
                    score  
                    hole{
                      id
                      number
                      par
                    }
                  }
                }
              }
            }
          }
          `,
        },
      });

      const scorecardsToAdd = userScorecards.data.data.me.scorecards.slice(
        0,
        this.fetchInterval
      );

      this.scorecards.push(...scorecardsToAdd);
      this.casualLoaded = true;

      if (
        userScorecards.data.data.me.scorecards.length ==
        this.fetchInterval + 1
      ) {
        this.canFetchMoreScorecards = true;
      }
    },
    async loadRegistrations() {
      this.canFetchMoreRegistrations = false;
      const userRegistrations = await this.$axios({
        headers: {
          Authorization: this.$store.state.user.sessionInfo.token,
        },
        data: {
          query: `
          {
            me{
              registrations(first:${this.fetchInterval}, skip: ${this.registrations.length} ){
                id
                status
                player{
                  onSite
                  userId
                  id
                }
                event{
                  id
                  name
                  status
                  isRegistrationOpen
                  registrationOpenAt
                  checkInStartsAt
                  checkInEndsAt
                  isVerifyMode
                  isResultSigningEnabled
                  startDate
                  endDate
                  pdgaType{
                    name
                    type
                  }
                  city{
                    name
                    country
                  }
                  rounds{
                    id
                    pools{
                      id
                      date
                    }
                  }
                  tour{
                    name
                    type{
                      name
                      type
                    }
                  }
                }
              }
            }
          }
          `,
        },
      });

      this.registrations.push(...userRegistrations.data.data.me.registrations);
      this.feedLength += this.fetchInterval;

      if (
        userRegistrations.data.data.me.registrations.length ==
        this.fetchInterval
      ) {
        this.canFetchMoreRegistrations = true;
      }
    },
    async loadData() {
      const userRegistrations = await this.$axios({
        headers: {
          Authorization: this.$store.state.user.sessionInfo.token,
        },
        data: {
          query: `
          {
            me{
              registrations(first:${this.registrationsStart}){
                id
                status
                player{
                  onSite
                  userId
                  id
                }
                event{
                  id
                  name
                  status
                  isRegistrationOpen
                  registrationOpenAt
                  checkInStartsAt
                  checkInEndsAt
                  isVerifyMode
                  isResultSigningEnabled
                  startDate
                  endDate
                  pdgaType{
                    name
                    type
                  }
                  city{
                    name
                    country
                  }
                  rounds{
                    id
                    pools{
                      id
                      date
                    }
                  }
                  tour{
                    name
                    type{
                      name
                      type
                    }
                  }
                }
              }
            }
          }
          `,
        },
      });

      this.registrations.push(...userRegistrations.data.data.me.registrations);

      if (
        userRegistrations.data.data.me.registrations.length <
        this.registrationsStart
      ) {
        this.canFetchMoreRegistrations = false;
      }
      this.eventsLoaded = true;
    },
  },
  mounted() {
    this.dataFromUserPlatform = navigator.platform + " " + navigator.userAgent;
    this.loadData();
    this.loadScorecards(6);
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";

.favourite-toggle {
  cursor: pointer;
}

.card-title-wrapper {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
}

.card-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding-right: 8px;
}
.dashboard-activity-container {
  padding: 0 18px;
  margin-top: 18px;
}

.upcoming-events-title {
  margin-bottom: 20px;
}
.finished-casual-title {
  margin-bottom: 20px;
  margin-top: 40px;
}
.dashboard-card {
  border-radius: 10px;
  border: 1px solid $sleet;
  padding: 18px 20px 22px 20px;
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.city {
  margin-top: 8px;
}
.dates {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  > p {
    margin-right: 12px;
  }
}
.labels {
  margin-top: 15px;
  padding-bottom: 18px;
}

.card-messages {
  width: 100%;
  padding-top: 18px;
  border-top: 1px solid $fog;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .check-in-message {
    margin-top: 10px;
  }
}

.buttons {
  margin-top: 20px;
  display: flex;
  width: 100%;

  .button-link {
    color: $midnight;
    text-decoration: none;
  }

  button {
    width: 100%;
    border: none;
    background: $cloud;
    border-radius: 6px;
    height: 48px;

    &:nth-child(2) {
      margin-left: 12px;
    }

    &.check-in-now {
      background: $sunrise;
      &:hover {
        background: #f0aa00;
      }
    }

    &:hover {
      background: $fog;
    }
  }
}
.load-more {
  width: 100%;
  justify-content: center;
  display: flex;
  p {
    color: $ocean;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .finished-casual-title {
    margin-bottom: 32px;
    margin-top: 48px;
  }
  .dashboard-activity-container {
    margin-top: 0;
    padding: 0;
  }
  .upcoming-events {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .upcoming-events-title {
    margin-bottom: 32px;
  }
  .dashboard-card {
    width: 31%;
    padding: 20px 22px 28px 22px;
    margin-right: 3.5%;
    margin-bottom: 32px;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .finished-casual-container,
  .favourite-events-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
</style>
