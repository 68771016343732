<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="24"
    viewBox="0 0 18 24"
    :class="color"
  >
    <path
      class="a"
      d="M13,0A8.986,8.986,0,0,0,4.826,12.75C6.249,15.846,13,24,13,24s6.751-8.154,8.174-11.25A8.986,8.986,0,0,0,13,0Z"
      transform="translate(-4)"
    />
    <path
      class="b"
      d="M2868.235,146.251v8h-4"
      transform="translate(-1911.248 -2125.735) rotate(45)"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckIn",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.grass {
  .a {
    fill: $grass;
  }
}
.a {
  fill: $sunrise;
}
.b {
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
}
</style>